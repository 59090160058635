<template>

    <div class="wrapContentIndex wrapContentIndexUnbox wrapAreaUnboxPlay GameType-unboxPlay " :class="{'UnboxPlayDiamondMode' : currency=='DIAMOND'}">
<!--    <div class="GameType GameType-unboxPlay">-->

        <FloatButtomRightPanel  :callChatWithAdmin="showChatWithAdminNumber"/>
        <div class="wrapContentIndexTitle">
            <button style="padding: 0 10px;" class="btn " @click="goHome()"><i class="fas fa-chevron-left"></i></button>
            <h3>กลับ</h3>
            <MenuBar  :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber" backRouteName="Unbox"></MenuBar>
        </div>
<!--        <div class="navTopDesktop">-->
<!--            <MenuNavTop @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNavTop>-->
<!--        </div>-->
<!--        <MenuBar  :callMenu="menuNumber" :callDWMenu="showDepositWithdrawNumber" backRouteName="Unbox"></MenuBar>-->
        <div class="wrapContentIndexInfo">
            <div class="contentFlexIndex">
                <div class="contentFlexIndexLeft active">
                    <MenuLeft></MenuLeft>
                </div>
                <div class="contentFlexIndexRight">

                    <div class="content-page content-page-unboxplay">
                            <div class="wrapper-case">
                                <div class="row-spin-content">
                                    <div class="main-spin-content">
                                        <div class="full-width-topSpin text-center" >
                                            <div class="container" style="position: relative; z-index: 0">
                                                <div class="header-tabs-title">
                                                    <div class="header-tabs-title-info">
                                                        <img v-bind:src="case_obj.choose_case" >
                                                        <div class="header-tabs-title-detail">
                                                            <strong>{{case_obj.name_case}}</strong>
                                                            <label>{{currency_list[currency]}}{{case_obj.price_case}}{{currency=='DIAMOND'?'K':''}}</label>
                                                        </div>
                                                    </div>
                                                    <!--                        <router-link :to="{name: 'Unbox'}">-->
                                                    <!--                        <span><i class="fas fa-chevron-left"></i></span>-->
                                                    <!--                        </router-link>-->

                                                    <div class="btn-circleSpin" @click="$router.push({'name':'SpinCircle',params:{'id':$route.params.id}})">
                                                        <!--                                    <img src="@/assets/images/icon/spin.gif"/>-->
                                                        <span>หมุนวงล้อ</span>
                                                    </div>

                                                </div>
                                                <div class="wrap-games-list" :class="'bt-jackpot-'+case_obj.bonus_color">
                                                <div class="container-fluid-jackpot" >
                                                    <div class="container-fluid-jackpot-info">
                                                        <div class="container-jackpot-grand">
                                                            <div class="detail">
                                                                <h2>GRAND</h2>
                                                                <ul>
                                                                    <li><span :class="'bonus-'+ case_obj.bonus_config_id+'-0'">{{case_obj.bonus_current[0]}}{{currency=='DIAMOND'?'K':''}}</span></li>
                                                                    <li><p>{{case_obj.bonus_range[0]}}{{currency=='DIAMOND'?'K':''}}</p></li>
                                                                </ul>
                                                            </div>
                                                            <div class="progress">
                                                                <div class="progress-bar" role="progressbar"  :aria-valuenow="case_obj.bonus_percent[0]" aria-valuemin="0" aria-valuemax="100" v-bind:style="{ width: case_obj.bonus_percent[0]+'%' }">
                                                                    <div class="wink"></div>
                                                                </div>
                                                            </div>
                                                            <div class="jackpot-bg"></div>
                                                        </div>
                                                        <div class="container-jackpot-minor">
                                                            <div class="detail">
                                                                <h2>MAJOR</h2>
                                                                <ul>
                                                                    <li><span :class="'bonus-'+ case_obj.bonus_config_id+'-1'">{{case_obj.bonus_current[1]}}{{currency=='DIAMOND'?'K':''}}</span></li>
                                                                    <li><p>{{case_obj.bonus_range[1]}}{{currency=='DIAMOND'?'K':''}}</p></li>
                                                                </ul>
                                                            </div>
                                                            <div class="progress">
                                                                <div class="progress-bar" role="progressbar" :aria-valuenow="case_obj.bonus_percent[1]"  aria-valuemin="0" aria-valuemax="100"  v-bind:style="{ width: case_obj.bonus_percent[1]+'%' }">
                                                                    <div class="wink"></div>
                                                                </div>
                                                            </div>
                                                            <div class="jackpot-bg"></div>
                                                        </div>
                                                        <div class="container-jackpot-minor" style="display: none;">
                                                            <div class="detail">
                                                                <h2>MINOR</h2>
                                                                <ul>
                                                                    <li><span :class="'bonus-'+ case_obj.bonus_config_id+'-2'">{{case_obj.bonus_current[2]}}{{currency=='DIAMOND'?'K':''}}</span></li>
                                                                    <li><p>{{case_obj.bonus_range[2]}}{{currency=='DIAMOND'?'K':''}}</p></li>
                                                                </ul>
                                                            </div>
                                                            <div class="progress">
                                                                <div class="progress-bar" role="progressbar" :aria-valuenow="case_obj.bonus_percent[2]"  aria-valuemin="0" aria-valuemax="100"  v-bind:style="{ width: case_obj.bonus_percent[2]+'%' }">
                                                                    <div class="wink"></div>
                                                                </div>
                                                            </div>
                                                            <div class="jackpot-bg"></div>
                                                        </div>
                                                        <div class="container-jackpot-mini" >
                                                            <div class="detail">
                                                                <h2>MINI</h2>
                                                                <ul>
                                                                    <li><span :class="'bonus-'+ case_obj.bonus_config_id+'-3'">{{case_obj.bonus_current[3]}}{{currency=='DIAMOND'?'K':''}}</span></li>
                                                                    <li><p>{{case_obj.bonus_range[3]}}{{currency=='DIAMOND'?'K':''}}</p></li>
                                                                </ul>
                                                            </div>
                                                            <div class="progress">
                                                                <div class="progress-bar" role="progressbar" :aria-valuenow="case_obj.bonus_percent[3]"  aria-valuemin="0" aria-valuemax="100"  v-bind:style="{ width: case_obj.bonus_percent[3]+'%' }">
                                                                    <div class="wink"></div>
                                                                </div>
                                                            </div>
                                                            <div class="jackpot-bg"></div>
                                                        </div>
                                                        <div class="container-jackpot-bonus">
                                                            <div class="detail">
                                                                <h2>FIXED</h2>
                                                                <ul>
                                                                    <li><span>{{case_obj.bonus_range[4]}}{{currency=='DIAMOND'?'K':''}}</span></li>
                                                                </ul>
                                                            </div>
                                                            <div class="jackpot-bg"></div>
                                                        </div>
                                                    </div>
                                                    <div class="container-fluid-jackpot-bg"></div>
                                                </div>
                                            </div>
                                                <div class="box-btn-top-spin">
                                                    <!--                            <div class="full-width text-center box-detail-spin text-center">-->
                                                    <!--&lt;!&ndash;                                <div class="btn-testSpin" id="case-spin-btn-all-test" @click="testSpin()">ทดสอบ</div>&ndash;&gt;-->

                                                    <!--                            </div>-->
                                                    <div class="area-amount-list">
                                                        <small>จำนวนครั้ง</small>
                                                        <div class="box-amount-list">
                                                            <div class="btn-amount dropdown-select-amount" :class="{ active: amount_active[0] }" @click="changeAmount('1')"><span>1</span></div>
                                                            <div class="btn-amount dropdown-select-amount" :class="{ active: amount_active[1] }"  @click="changeAmount('2')"><span>2</span></div>
                                                            <div class="btn-amount dropdown-select-amount" :class="{ active: amount_active[2] }"  @click="changeAmount('3')">
                                                                <span>3</span>
                                                                <div class="btn-amount-price"></div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="box-volume-right">
                                                        <p>เสียง</p>
                                                        <span class="box-volume" @click="changeMute(1)" v-if="is_mute==0"><i id="is_mute" _value="0" class="fas fa-volume-up"></i></span>
                                                        <span class="box-volume-mute" @click="changeMute(0)" v-if="is_mute==1"><i id="is_mute" _value="1" class="fas fa-volume-mute"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        <div class="container-spin-game">
                                            <div class="bg-box-btnSpin">
                                                <div class="bg-box-btnSpin-circle"></div>
                                            </div>
                                            <div class="container-spin-game-info">
                                                <div v-for="(_item_list,case_index_key) of item_list" :key="case_index_key">
                                                    <div   v-if="case_index_key<=bet_amount-1"  class="main-case-box card z-depth-2 " :class="'main-case-box-level-'+bet_amount" :id="'main-case-box-'+case_index_key" style="width: 100%; position: relative;" >
                                                        <div class="case-box">
                                                            <div class="case-container" style="display: block;">
                                                                <div class="case-inner" :id="'div-case-inner-'+case_index_key">
                                                                    <div v-for="(item, item_key) of _item_list" :key="item_key"
                                                                         class="case-item z-depth-1" :class="['case-item-'+color_maping[item.quality_color]]" :id="'div-case-item-'+case_index_key+'-'+item_key"
                                                                         v-bind:style="{ 'border-left-color': '#'+item.quality_color, 'border-right-color':'#'+item.quality_color,'background-color':convertColor(item.quality_color,0.3) }" >
                                                                        <div class="border-top-style" v-bind:style="{'background-color':convertColor(item.quality_color,0.9) }"></div>
                                                                        <div class="case-item-shadow">&nbsp;</div>
                                                                        <img class="case-item-img" draggable="false"  :src="item.icon_url">
                                                                        <div class="case-item-value white-text" style="background: -moz-linear-gradient(left, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%), #808080;background: -webkit-linear-gradient(left, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.6) 100%), #808080;background: linear-gradient(to right, rgba(0,0,0,0.2) 0%,rgba(0,0,0,0.6) 100%), #808080; cursor: default;"
                                                                             v-bind:style="{'background-color':convertColor(item.quality_color,0.9) }" >
                                                                            <div style="max-height:38px; overflow: hidden"></div>
                                                                            <div class="item-name">{{item.item_name}}</div>
                                                                        </div>
                                                                        <div class="case-item-bg">&nbsp;</div>
                                                                    </div>
                                                                </div>
                                                                <div class="case-marker">&nbsp;</div>
                                                            </div>
                                                            <!--					<button number="0" id="case-spin-btn" class="btn waves-effect waves-light green" style="display: block;">Spin!</button>-->
                                                            <div class="center-align" style="background-color:rgba(0, 0, 0, 0.8);position: absolute; top: 0px; width: 100%;height: 100%; font-size: 1.2em; display: none;" >
                                                                <img idraggable="false" style="padding-top: 20px;top: 0px; width: 150px; height:auto;" src="">
                                                                <div style="color: #fff;"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="bg-image" v-bind:style="{ 'background-image': 'url('+case_obj.item_img+')' }" ></div>
                                            </div>
                                        </div>

                                        <!--                        spin_balance_not_enough-->
                                        <!--                        disabled="disabled"-->
                                        <!--  ////////////////////////////////////  ปุ่ม Spin-->
                                        <div class="box-btnSpinInfo">
                                            <div class="full-width text-center box-btnSpin">
                                                <div class="col-box-btnSpin">
                                                    <div  v-if="false" class="btn btn-lg-test btn-lg"  id="case-spin-btn-all-test" @click="testSpin()">
                                                        <span></span>
                                                    </div>
                                                </div>
                                                <!--                                <div class="btn-lg-spin spin_auto_action" disabled="disabled" id="case-spin-btn-all" v-on:click="singleSpin()" :class="{'spin_auto_action':is_auto}">-->
                                                <div class="btn-lg-spin"  id="case-spin-btn-all" v-on:click="singleSpin()" :class="{'spin_auto_action':is_auto}">
                                                    <div class="box-btn-unbox">
                                                        <span class="box-btn-unbox-text" id="case_price2" ></span>
                                                        <div class="box-btn-unbox-price">
                                                            <span class="text-price" id="case_price" v-bind:price="case_obj.price_case"  currency="THB">{{unbox_price_str}}</span>
                                                            <span class="badge"  id="bet_amount" :value="bet_amount">{{bet_amount}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="bg-btn-unbox"></div>
                                                </div>
                                                <div class="col-box-btnSpin">
                                                    <div class="btn btn-lg box-btnSpin-auto btnSpin-start" v-if="is_auto==false" @click="clickStartAuto()">
                                                        <div class="b-start">อัตโนมัติ</div>
                                                        <div class="b-stop">หยุด</div>
                                                        <!--                                    <small>unbox</small>-->
                                                    </div>
                                                    <div class="btn btn-lg box-btnSpin-auto btnSpin-stop" v-else  @click="clickStopAuto()">
                                                        <div class="b-start">อัตโนมัติ</div>
                                                        <div class="b-stop">หยุด</div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div class="container" style="position: relative;">
                                            <div class="full-width">
                                                <div class="header-tabs full-width">
                                                    <!--                                <strong>This case contains</strong>-->
                                                    <div class="filter-color">
                                                        <div class="filter-color-bx">
                                                            <!--                                            <label>Filter Color Rate</label>-->
                                                            <label>อัตราการสุ่มสินค้า</label>
                                                            <ul>
                                                                <li :class="{active:showItemActive[0]}" @click="changeShowItem(0)"><span class="all-filter">All</span></li>
                                                                <li :class="{active:showItemActive[1]}" @click="changeShowItem(1)"><span class="gold-filter"></span></li>
                                                                <li :class="{active:showItemActive[2]}" @click="changeShowItem(2)"><span class="red-filter"></span></li>
                                                                <li :class="{active:showItemActive[4]}" @click="changeShowItem(4)"><span class="pink-filter"></span></li>
                                                                <li :class="{active:showItemActive[3]}" @click="changeShowItem(3)"><span class="purple-filter"></span></li>
                                                                <li :class="{active:showItemActive[5]}" @click="changeShowItem(5)"><span class="blue-filter"></span></li>
                                                                <li :class="{active:showItemActive[6]}" @click="changeShowItem(6)"><span class="white-filter"></span></li>
                                                            </ul>
                                                        </div>

                                                        <div style="clear: both"></div>
                                                        <div class="box-btn-all-filter">
                                                            <button type="button" class="btn" @click="hideAllItem()">
                                                                {{hideAndShowStr}}
                                                            </button>
                                                            <button v-if="false" type="button" class="btn" @click="generateNewServerSeed()">
                                                                Generate
                                                                New Server Seed
                                                            </button>
                                                            <a v-if="false" class="btn" href="#" target="_blank">Fair</a>
                                                            <button type="button" class="btn btn-danger" v-if="false" >Disabled Auto Sell</button>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="wrapper-cards">
                                                    <div class="clearfix"></div>
                                                    <div v-for="(item,item_key) of all_item_list" :key="item_key" :color_level="color_class_level[item.quality_color]">
                                                        <div  class="wrap-card-box" v-if="item.is_show">
                                                            <div class="card-box" :class="'case-item-'+color_maping[item.quality_color]" style="border-width: 2px; border-color:#d99700">
                                                                <div class="card-text-title"> % Range: {{item.start}} - {{item.end}} <br>Odds: {{item.percent}}%</div>
                                                                <div class="card-box-body card-body-items">
                                                                    <div class="box-items" :style="{'background-image' : 'url('+item.icon_url+')'}" style="background-image: url('https://pesstar.com/images/caseitem/data_10001/4.png');"></div>
                                                                </div>
                                                                <div class="card-name card-name-items">
                                                                    <span>{{item.item_name}}</span>
                                                                </div>
                                                                <div class="card-price text-center">{{currency_list[currency]}}{{item.prices}}{{currency=='DIAMOND'?'K':''}} </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                        <div style="clear: both"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                </div>
            </div>
            <div class="navButtonMobile">
                <MenuNav   @showMenuNav="showMenuNav"  @showChatWithAdmin="showChatWithAdmin" @showDepositWithdrawModal="showDepositWithdrawModal"></MenuNav>
            </div>
        </div>


        <!-- open case jackpot-->
        <div class="modal popup-jackpot fade" id="modal-show-reward-item">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body" style="position: relative; z-index: 1;">
                <div class="modal-body modal-sigle-body" style="position: relative">
                    <div class="box-case-list" :class="'box-case-list-'+color_maping[item_single.name_color]+''">
                        <h2 class="show-reward-case-name">{{case_obj.name_case}}</h2>


                        <div class="item-openCase">

                            <div id="show-reward-item-img"
                                 :style="'background-image: url('+item_single.item_img+')'"></div>
                        </div>
                        <h4 id="show-reward-item-name">{{item_single.item_name}}</h4>
                        <div class="pull-left full-width">
                            <ul>
                                <li>Provably : <span id="show-reward-provably">{{item_single.provably_percent}}%</span></li>
                                <li>Provably Fair Result : <span id="show-reward-provably-result">{{item_single.provably_fair_result}}</span></li>
                                <li>Provably Fair Data
                                    <button class="btn btn-xs btn-primary" type="button" data-toggle="collapse"
                                            data-target="#show-reward-item-div">
                                        Show
                                    </button>
                                </li>
                                <li></li>
                            </ul>
                        </div>
                        <div style="clear: both;"></div>
                        <div class="collapse" id="show-reward-item-div">
                            <div class="form-horizontal text-left">
                                <div class="form-group" style="padding: 0;">
                                    <label class="col-sm-4 control-label">Server Seed Hash</label>
                                    <div class="col-sm-7">
                                <span class="label label-default" id="show-reward-server-seed-hash">
                                    {{item_single.server_seed_hash}}
                                </span>
                                    </div>
                                </div>
                                <div class="form-group" style="padding: 0;">
                                    <label class="col-sm-4 control-label">Client Seed</label>
                                    <div class="col-sm-7">
                                <span class="label label-default" id="show-reward-client-seed">
                                    {{item_single.client_seed}}
                                </span>
                                    </div>
                                </div>
                                <div class="form-group" style="padding: 0;">
                                    <label class="col-sm-4 control-label">Nonce</label>
                                    <div class="col-sm-7">
                                <span class="label label-default" id="show-reward-nonce">
                                    {{item_single.nonce}}
                                </span>
                                    </div>
                                </div>
                            </div>
                            <div style="clear: both;"></div>
                        </div>
                        <div style="clear: both;"></div>
                        <div class="total-box1">
                            <span>Total Received</span>
                            <span>{{currency_list[currency]}}{{item_single.result_return}}{{currency=='DIAMOND'?'K':''}}</span>
                        </div>
                        <div v-if="lastItemRecieve.length>0" class="total-box1">
                            <span>GameID</span>
                            <span>#{{`${lastItemRecieve[0].gid}`}}</span>
                        </div>
                        <div class="modal-footer box-open-case text-center">
                            <button type="button" class="btn btn-lg" @click="sellSingleBtn()" id="sell-single-btn"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

        <!-- open case multi jackpot-->
        <div class="modal popup-jackpot popup-jackpot-multi fade" id="modal_keep_sell_v2">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-body" style="position: relative; z-index: 1;">
                <div class="full-width">
                    <h2 class="show-reward-case-name"></h2>
                </div>
                <div class="content-multiCase full-width" id="tbody-item-recieved_v2">
                    <div id="_tbody-item-recieved_v2">
                        <div v-for="(item,key) of item_multi" :key="key">
                            <div class="box-case-list" :class="'box-case-list-'+color_maping[item.name_color]+''">

                                <div class="item-openCase">
                                    <div :style="'background-image: url('+item.icon_url+')'"></div>
                                </div>
                                <div class="content-itemCase">
                                    <h4>{{item.item_name}}</h4>
                                    <div class="pull-left full-width">
                                        <ul>
                                            <li>Provably : <span>{{item.provably_percent}}%</span></li>
                                            <li>Provably Fair Result : <span>{{item.provably_fair_result}}</span></li>
                                            <li>Provably Fair Data
                                                <button class="btn btn-xs btn-primary" type="button" data-toggle="collapse"
                                                        :data-target="'#collapseTest'+key">
                                                    Show
                                                </button>
                                            </li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div style="clear: both;"></div>
                                    <div class="collapse" :id="'collapseTest'+key">
                                        <div class="form-horizontal text-left">
                                            <div class="form-group">
                                                <label class="col-sm-4 control-label">Server Seed Hash</label>
                                                <div class="col-sm-7">
                                        <span class="label label-default">
                                            {{item.server_seed_hash}}
                                        </span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-sm-4 control-label">Client</label>
                                                <div class="col-sm-7">
                                        <span class="label label-default">
                                            {{item.client_seed}}
                                        </span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-sm-4 control-label">Nonce</label>
                                                <div class="col-sm-7">
                                        <span class="label label-default">
                                            {{item.nonce}}
                                        </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="clear: both;"></div>
                                    </div>
                                    <div class="btn btn-sell">
                                        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                                        {{currency_list[currency]}}{{item.result_return}}{{currency=='DIAMOND'?'K':''}}
                                    </div>
                                    <div style="clear: both;"></div>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="total-box1">
                        <span>Total Received</span>
                        <span>{{currency_list[currency]}}{{total_sum}}{{currency=='DIAMOND'?'K':''}}</span>
                    </div>
                    <div v-if="lastItemRecieve.length>0" class="total-box1">
                            <span>GameID</span>
                            <span>{{`#${lastItemRecieve[0].gid}-${lastItemRecieve[lastItemRecieve.length-1].gid}`}}</span>
                    </div>
                </div>
                <div class="modal-footer box-open-case text-center">
                    <button type="button" class="btn btn-lg" @click="sellAllBtn()" id="sell-all-btn"></button>
                </div>
            </div>

            <!--            <div class="bg-modal"></div>-->
        </div>
    </div>
</div>

        <!-- กล่องตกลงมา-->
        <div class="popup-jackpot" style="display: none;">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-body">
                        <div class="content-multiCase full-width" >
                            <div class="title"><h5>You Items</h5></div>
                            <div class="box-case-list box-case-list-purple">

                                <div class="item-openCase">

                                    <div class="item-openCase-p" :style="'background-image: url('+case_obj.choose_case+')'"></div>
                                    <div class="item-openCase-bg"></div>
                                </div>
                                <div class="content-itemCase">
                                    <h4>Basics Silicone Case for Apple iPhone X (Blue)</h4>
                                    <strong>฿1.5</strong>
                                    <div class="pull-left full-width">
                                        <ul>
                                            <li>Provably : <span>52.6162%</span></li>
                                            <li>Provably Fair Result : <span>99.61623</span></li>
                                            <li>Provably Fair Data
                                                <button class="btn btn-xs btn-primary" type="button" data-toggle="collapse"
                                                        data-target="#collapseTest1">
                                                    Show
                                                </button>
                                            </li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div class="collapse" id="collapseTest1">
                                        <div class="form-horizontal text-left">
                                            <div class="form-group">
                                                <label class="col-sm-4 control-label">Server Seed Hash</label>
                                                <div class="col-sm-7">
                                <span class="label label-default">
                                    9c82bd85e242195c375689ffd103c
                                </span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-sm-4 control-label">Client</label>
                                                <div class="col-sm-7">
                                <span class="label label-default">
                                    FDcs4abEIBVkRoicuxuY
                                </span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-sm-4 control-label">Nonce</label>
                                                <div class="col-sm-7">
                                <span class="label label-default">
                                    122276
                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="clear: both;"></div>
                                    </div>

                                </div>
                            </div>
                            <div class="box-case-list box-case-list-white" style="display: none">
                                <div class="item-openCase">
                                    <div class="item-openCase-p" style="background-image: url('https://pesstar.com/images/caseitem/data_10044/v50.png')"></div>
                                    <div class="item-openCase-bg"></div>
                                </div>
                                <div class="content-itemCase">
                                    <h4>Operation Phoenix Weapon Case</h4>
                                    <strong>฿1.5</strong>
                                    <div class="pull-left full-width">
                                        <ul>
                                            <li>Provably : <span>52.6162%</span></li>
                                            <li>Provably Fair Result : <span>99.61623</span></li>
                                            <li>Provably Fair Data
                                                <button class="btn btn-xs btn-primary" type="button" data-toggle="collapse"
                                                        data-target="#collapseTest2">
                                                    Show
                                                </button>
                                            </li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div style="clear: both;"></div>
                                    <div class="collapse" id="collapseTest2">
                                        <div class="form-horizontal text-left">
                                            <div class="form-group">
                                                <label class="col-sm-4 control-label">Server Seed Hash</label>
                                                <div class="col-sm-7">
                                <span class="label label-default">
                                    9c82bd85e242195c375689ffd103c
                                </span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-sm-4 control-label">Client</label>
                                                <div class="col-sm-7">
                                <span class="label label-default">
                                    FDcs4abEIBVkRoicuxuY
                                </span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-sm-4 control-label">Nonce</label>
                                                <div class="col-sm-7">
                                <span class="label label-default">
                                    122276
                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="clear: both;"></div>
                                    </div>
                                    <div style="clear: both;"></div>

                                </div>
                            </div>
                            <div class="box-case-list box-case-list-blue" style="display: none;">
                                <div class="item-openCase">
                                    <div class="item-openCase-p" style="background-image: url('https://pesstar.com/images/caseitem/data_10044/57.png')"></div>
                                    <div class="item-openCase-bg"></div>
                                </div>
                                <div class="content-itemCase">
                                    <h4>Operation Phoenix Weapon Case</h4>
                                    <strong>฿1.5</strong>
                                    <div class="pull-left full-width">
                                        <ul>
                                            <li>Provably : <span>52.6162%</span></li>
                                            <li>Provably Fair Result : <span>99.61623</span></li>
                                            <li>Provably Fair Data
                                                <button class="btn btn-xs btn-primary" type="button" data-toggle="collapse"
                                                        data-target="#collapseTest3">
                                                    Show
                                                </button>
                                            </li>
                                            <li></li>
                                        </ul>
                                    </div>
                                    <div style="clear: both;"></div>
                                    <div class="collapse" id="collapseTest3">
                                        <div class="form-horizontal text-left">
                                            <div class="form-group">
                                                <label class="col-sm-4 control-label">Server Seed Hash</label>
                                                <div class="col-sm-7">
                                <span class="label label-default">
                                    9c82bd85e242195c375689ffd103c
                                </span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-sm-4 control-label">Client</label>
                                                <div class="col-sm-7">
                                <span class="label label-default">
                                    FDcs4abEIBVkRoicuxuY
                                </span>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-sm-4 control-label">Nonce</label>
                                                <div class="col-sm-7">
                                <span class="label label-default">
                                    122276
                                </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="clear: both;"></div>
                                    </div>
                                    <div style="clear: both;"></div>

                                </div>
                            </div>
                            <div class="content-multiCase-footer">
                                <span>Total Received</span>
                                <strong>฿0.03</strong>
                            </div>
                        </div>
                        <div class="modal-footer box-open-case text-center">
                            <button type="button" class="btn btn-lg"><i class="fas fa-check"></i></button>
                        </div>
                    </div>
                    <!--            <div class="bg-modal"></div>-->
                </div>
            </div>
            <div class="popup-jackpot-backdrop">

            </div>
        </div>

        <!--POPUP JACKPORT GRAND-->
        <div class="modal-wide-screen modalJackpotGrand" style="display: none;" id="ModalGrand">
            <div id="gimmick"></div>
            <div class="box-jackpot-content-detail">
                <div class="modal-jackpot-grand-num">{{case_obj.bonus_current[0]}}</div>
                <div class="top-detail btn close_modal_bonus" data-dismiss="modal" aria-label="Close" @click="closeBonusModal('ModalGrand')">COLLECT</div>
            </div>
            <div class="parallax">
                <div class="item-jpot-g-top-2 item-side1">
                    <img src="@/assets/images/bg/bg-jackpot/g-jackpot-6.png">
                </div>
                <div class="item-jpot-g-top item-side2">
                    <img src="@/assets/images/bg/bg-jackpot/g-jackpot-5.png">
                </div>
            </div>
            <div class="box-jackpot-content">
                <div class="top-detail">

                    <img src="@/assets/images/bg/bg-jackpot/g-jackpot-bg-spin.png">
                </div>
            </div>
        </div>

        <!--POPUP JACKPORT major-->
        <div class="modal modal-major" id="ModalMajor">
            <div class="top-popup-major">
                <div class="btn close close_modal_bonus" data-dismiss="modal" aria-label="Close" @click="closeBonusModal('ModalMajor')">
                    collect
                </div>
            </div>
            <div class="parallax">
                <div class="item-jpot-g-top-2 item-side1">
                    <img src="@/assets/images/bg/bg-jackpot/g-jackpot-6.png">
                </div>
            </div>
            <div class="content-popup-major">
                <h3>{{case_obj.bonus_current[1]}}</h3>
            </div>
        </div>

        <!--POPUP JACKPORT minor-->
        <div class="modal modal-minor" id="ModalMinor">
            <div class="top-popup-minor">
                <div class="btn close close_modal_bonus" data-dismiss="modal" aria-label="Close" @click="closeBonusModal('ModalMinor')">
                    collect
                </div>
            </div>
            <div class="parallax">
                <div class="item-jpot-g-top-2 item-side1">
                    <img src="@/assets/images/bg/bg-jackpot/g-jackpot-6.png">
                </div>
            </div>
            <div class="content-popup-minor">
                <h3>{{case_obj.bonus_current[2]}}</h3>
            </div>
        </div>

        <!--POPUP JACKPORT Mini-->
        <div class="modal modal-mini" id="ModalMini">
            <div class="top-popup-mini">
                <div class="btn close close_modal_bonus" data-dismiss="modal" aria-label="Close" @click="closeBonusModal('ModalMini')">
                    collect
                </div>
            </div>
            <div class="content-popup-mini">
                <h3>{{case_obj.bonus_current[3]}}</h3>
            </div>
        </div>

        <!--POPUP JACKPORT bonus-->
        <div class="modal modal-bonus fade" id="ModalBonus">
            <div class="top-popup-bonus">
                <div class="btn close close_modal_bonus" data-dismiss="modal" aria-label="Close" @click="closeBonusModal('ModalBonus')">
                    collect
                </div>
            </div>
            <div class="content-popup-bonus">
                <h3>{{case_obj.bonus_current[4]}}</h3>
            </div>
        </div>
    </div>
</template>

<script>
var currentCase;
var userConfigGame;
var listCaseGroup;
var ITEMLIST;
var token;
var SP_BONUS_ITEM;
let case_start = new Audio(require("@/assets/sound/csgo_ui_crate_open.wav"));
let canPlayClick = true;
let clickInterval = 0;
let starburst = $(".starburst");
starburst.hide();
var lastItemRecieve = [];
var bonus_reward = false;
var spin_again = false;
var bonus_obj;
var sum_count = 0;
var num = 1;
let offsetOverride = -1;
// volume for cases
let masterVolume = 0.25;
var all_audio_file = {};
// All the case sounds

case_start.volume = masterVolume * 0.5;
all_audio_file['case_start'] = case_start;
let clicks = []

for (let i = 0; i < 20; i++) {
    clicks[i] = new Audio(require("@/assets/sound/csgo_ui_crate_item_scroll.wav"));
    clicks[i].volume = masterVolume * 0.5;
    all_audio_file['click_' + i] = clicks[i];
}

let clicknum = 0;

let case_done = {};

case_done.blue = new Audio(require("@/assets/sound/item_reveal3_rare.wav"));
case_done.blue.volume = masterVolume * 0.5;
all_audio_file['case_done_blue'] = case_done.blue;
case_done.purple = new Audio(require("@/assets/sound/item_reveal5_legendary.wav"));
case_done.purple.volume = masterVolume * 0.5;
all_audio_file['case_done_purple'] = case_done.purple;

case_done.pink = new Audio(require("@/assets/sound/item_reveal4_mythical.wav"));
case_done.pink.volume = masterVolume * 0.6;
all_audio_file['case_done_pink'] = case_done.pink;

case_done.red = new Audio(require("@/assets/sound/item_reveal6_ancient.wav"));
case_done.red.volume = masterVolume * 0.8;
all_audio_file['case_done_red'] = case_done.red;

case_done.gold = new Audio(require("@/assets/sound/item_reveal6_ancient.wav"));
case_done.gold.volume = masterVolume * 0.8;
all_audio_file['case_done_gold'] = case_done.gold;
var is_test =false;
var item_test_list =[];
var item_test_count=0;


var color_class_mapping = {
    'd99700': 'gold',
    'c9005a': 'red',
    '7900df': 'purple',
    'c700df': 'pink',
    '2a5aab': 'blue',
    'b4b4b2': 'white',
};
const color_class_level={
    
    'd99700': 1,
    '7900df': 3,
    'c9005a': 2,
    'c700df': 4,
    '2a5aab': 5,
    'b4b4b2': 6,
}
var modal_option={
    show:true,
    backdrop:false,
};
// import MenuLeft from '@/components/MenuLeft'
import FloatButtomRightPanel from '@/components/FloatButtomRightPanel'
import LayoutMixin from '@/mixins/Layout'
import store from '@/store'
import axiosWrapper from '@/axiosWrapper'
import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import BigNumber from 'bignumber.js'
import sha512 from 'js-sha512'
import bez from  'jquery-bez'
import ReconnectingWebSocket from 'reconnecting-websocket'
var ws;
bez

var BONUS_LEVEL_MODAL=['ModalGrand','ModalMajor','ModalMinor','ModalMini','ModalBonus'];

var SELF_VUE;
import MenuBar from '@/components/MenuBar'
import MenuLeft from '@/components/MenuLeft'
import MenuNav from '@/components/MenuNav'
// import MenuNavTop from '@/components/MenuNavTop'
// import MenuBarUnbox from '@/components/menu-bar/Unbox'
export default {
    name: 'GameType',
    components: {
        FloatButtomRightPanel,
        MenuBar,
        MenuLeft,
        MenuNav,
        // MenuNavTop,
        // MenuBarUnbox,
    },
    mixins: [
        LayoutMixin,
    ],
    data() {
        return {
            mode:0,
            currency_list :{
                'THB':'฿',
                'DIAMOND':'',
            },
            showChatWithAdminNumber:0,
            showDepositWithdrawNumber:0,
            menuNumber :0,
            is_mute:0,
            gameGroups: [
                {
                    icon : '/images/casino/logo-sexy-baccarat.png',
                    title : 'Sexy Baccarat',
                },
                {
                    icon : '/images/casino/sa-casino.png',
                    title : 'SA Baccarat',
                },
                {
                    icon : '/images/casino/logo-111.png',
                    title : 'Pragmatic Play',
                },
                {
                    icon : '/images/casino/play-bacarat.jpg',
                    title : `King's Poker`,
                },
            ],
            lastItemRecieve:[],
            case_obj : {
                bonus_current:[0,0,0,0,0],
                bonus_range:[0,0,0,0,0],
                bonus_percent:[0,0,0,0,0],
            },
            case_group :{},
            case_item : [],
            bonus_list :[],
            unbox_price_str:'',
            item_list :[
                [],
                [],
                [],
            ],
            color_maping : color_class_mapping,
            amount_active:[
                false,
                false,
                true
            ],
            bet_amount:this.$route.query.amount?this.$route.query.amount:1,
            showItemActive:[
                true,
                false,
                false,
                false,
                false,
                false,
                false
            ],
            all_item_list:[],
            color_class_level:color_class_level,
            show_item_btn :false,
            hideAndShowStr :'HideAllItem',
            item_single:{},
            item_multi:[],
            total_sum:0,
            is_auto:false,
            bonus:[
                {bonus_current:null},
                {bonus_current:null},
                {bonus_current:null},
                {bonus_current:null},
                {bonus_end:null},
            ],
            currency :'THB',
        }
    },
    destroyed: function() {
    //  console.log('my-component destroyed');
     ws.close();
    },
        computed: {
                is_member() {
                    return store.state.is_member
                },
                pes_profile() {
                    return store.state.pes_profile?store.state.pes_profile:{
                        sp_bonus_item:{
                            spiner:{}
                        }
                    }
                },
                token() {
                    return  this.pes_profile.token?this.pes_profile.token:null;
                },
        },
        methods: {
            showChatWithAdmin(){
                this.showChatWithAdminNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showChatWithAdminNumber=0;
                },100);
                
            },
            showDepositWithdrawModal(){
                this.showDepositWithdrawNumber=1;
                let self =this;
                setTimeout(()=>{
                    self.showDepositWithdrawNumber=0;
                },100);
                
        },

            showMenuNav(menuNumber){
                this.menuNumber = menuNumber;
                let self =this;
                setTimeout(()=>{
                    self.menuNumber=0;
                },1000);
            },
            goHome() {
                this.$router.go(-1)
                // this.$router.push({name: 'IndexUnbox'})
            },
            changeMute(is_mute){
                if(is_mute){
                    this.is_mute=is_mute;
                    this.$cookies.set("mute_all_audio",is_mute,"1m");  // 1 month after, expire
                }else{
                    this.is_mute=is_mute;
                    this.$cookies.set("mute_all_audio",is_mute,"1m");  // 1 month after, expire
                }
            },
                async getDataByGame() {
                const res = await axiosWrapper({
                    method: 'get',
                    url: `${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v0/getDataByGame.php?id=`+this.$route.params.id
                });
                return res.data;
             },
             openGame() {
             },
             convertColor(color,opacity){
                var rgbaCol = 'rgba(' + parseInt(color.slice(-6, -4), 16)
                    + ',' + parseInt(color.slice(-4, -2), 16)
                    + ',' + parseInt(color.slice(-2), 16)
                    + ','+opacity+')';
                    return rgbaCol;
            },
            singleSpin(){

                if(this.token){
                    sum_count=0;
                    var amount = parseInt($('#bet_amount').text());
                    if(!$('#case-spin-btn-all').attr('disabled')){
                        betGameMuti(0, amount, false)
                    }else{
                        console.log('disabled...');
                    }
                }else{
                    this.notifyToSignUp('เพื่อเล่น')
                }

                
            },
            testSpin(){
                var amount = parseInt($('#bet_amount').text());
                if(!$('#case-spin-btn-all-test').attr('disabled')){
                    $('#case-spin-btn-all').addClass('spin_balance_not_enough');
                    $('#case-spin-btn-all-test').addClass('active');
                    betGameMuti(0, amount, true);
                }
               
            },
            sellAllBtn(){
                
                setTimeout(function () {
                        $('#modal_keep_sell_v2').modal('hide');
                    }, 500);

            },
            sellSingleBtn(){
                    setTimeout(function () {
                        $('#modal-show-reward-item').modal('hide');
                    }, 500);
            },
            changeAmount(amount){
                
                if(this.is_auto==false){
                    $('#case-spin-btn-all').removeAttr('disabled');
                    this.bet_amount =amount;
                    if(amount==1){
                        this.amount_active=[
                            true,false,false
                        ];

                    }else if(amount==2){
                        this.amount_active=[
                            false,true,false
                        ];
                    }else if(amount==3){
                        this.amount_active=[
                            false,false,true
                        ];
                    }
                                
                                for(var number=0;number<amount;number++){
                                    this.item_list[number]=initCaseData(number);
                                }
                                showSpinBtn(amount);
                                
                }
                
                

            },
            changeShowItem(number,hide){
                this.showItemActive=[
                    false,
                    false,
                    false,
                    false,
                    false,
                    false,
                    false
                ];
                if(hide!=true){
                    this.show_item_btn=true;
                    this.hideAndShowStr='HideAllItem';
                    this.showItemActive[number]=true;
                if(number==0){
                    for(var i=0;i<this.all_item_list.length;i++){
                        this.all_item_list[i].is_show=true;
                    }
                }else{
                    for(var i2=0;i2<this.all_item_list.length;i2++){
                        if(color_class_level[this.all_item_list[i2].quality_color]==number){
                            this.all_item_list[i2].is_show=true;
                        }else{
                             this.all_item_list[i2].is_show=false;
                        }
                        
                    }
                }
                }else{
                    this.show_item_btn=false;
                    this.hideAndShowStr='ShowAllItem';
                    for(var i3=0;i3<this.all_item_list.length;i3++){
                        this.all_item_list[i3].is_show=false;
                    }
                }
                
            },
            hideAllItem(){
                if(this.show_item_btn){
                    this.show_item_btn=false;
                    this.hideAndShowStr='ShowAllItem';
                    this.changeShowItem(0,true);
                }else{
                    this.show_item_btn=true;
                    this.hideAndShowStr='HideAllItem';
                    this.changeShowItem(0,false);

                }
            },
            clickStartAuto(){
                if(this.token){
                    if(this.is_auto==false){
                        this.is_auto=true;

                        this.singleSpin();
                    }
                }else{
                    this.notifyToSignUp('เพื่อเล่น')
                }
                

            },
            clickStopAuto(){
                if(this.is_auto==true){
                    this.is_auto=false;     
                }
                
            },
            closeBonusModal(id){
                
                if((id=='ModalGrand' || id=='ModalBonus')){
                    $('#'+id).hide();
                    
                }else{
                    $('#'+id).modal('hide');
                }
            },
            generateNewServerSeed(){
            var cf = confirm('Do you want generate new server seed?');
            if(cf){
        $.post(`${this.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/generateNewServerSeed.php`,{token:this.token},function(response){
            if(response.code==1){
                alert(response.msg);
                // var old_server_seed = response.data.old_server_seed;
                // var server_seed = old_server_seed;
                // var client_seed = $('#show-item-client-seed').text();
                // var server_seed_hash = $('#show-item-server-seed-hash').text();
                // var nonce = $('#show-item-nonce').text();
                // $('#show-item-server-seed').text(old_server_seed);

                // $('#show-item-link-verify').attr('href','/game_fair.php?server_seed='+server_seed+'&client_seed='+client_seed+'&nonce='+nonce).attr('target','_blank');
                // $('#show-item-link-verify').children().text('Click For Verify').removeClass('generateNewServerSeed');
                // $('div.btn-info-side').each(function(){
                //     var server_seed_div = $(this).attr('server_seed');
                //     var server_seed_hash_div  = $(this).attr('server_seed_hash');
                //     if(server_seed_div=='null' || server_seed_div==null || server_seed_div==undefined || server_seed_div=='undefined'){
                //         if(server_seed_hash_div==server_seed_hash){
                //             $(this).attr('server_seed',server_seed);
                //         }
                //     }
                // });

            }else{
                alert(response.msg);
            }
        });
    }

},
            initWs() {
                    var hostname =`${this.envDomains.VUE_APP_PES_WS_BASE_URL}`;
                    var token = this.token;
                    ws = new ReconnectingWebSocket(hostname, token, {debug: false, reconnectInterval: 3000});
                    ws.onmessage = function (msg) {
                        var response = JSON.parse(msg.data);
                        manageWSContent(response, 'all');

                    }
                    ws.onopen = function () {
                        var data = {'type': 'subscribe', list: ['chat', 'spiner']};
                        wsSendData(data);
                    };

                },
            scrollToTop() {
                window.scrollTo(0,0);
           },
        },
        async mounted() {
            
            if(this.$cookies.isKey('mute_all_audio')){
                this.is_mute=(this.$cookies.get('mute_all_audio'));
            }
            SELF_VUE = this;
            this.scrollToTop()
            initUserConfigGame();
            
            var pes_profile=this.pes_profile;
            SP_BONUS_ITEM =pes_profile? pes_profile.sp_bonus_item:{};
            token = this.token;
            if(pes_profile){
                this.mode=pes_profile.mode
            }
            var obj= await  this.getDataByGame();
            var case_obj = obj.all_case[0];
            this.currency = case_obj.game_case;
            case_obj.bonus_percent=[0,0,0,0,0];
            case_obj.bonus_current =[0,0,0,0,0];
            case_obj.bonus_range =[0,0,0,0,0];
            this.case_obj =case_obj;

            var case_group = obj.all_case_group;
            this.case_group = case_group;
            var case_item = obj.all_case_item;
            this.case_item = case_item;
            var all_item_list =case_group;
            var item_key_list ={};
            for(var key_item =0;key_item<case_item.length;key_item++){
                var item = case_item[key_item];
                item_key_list[item.id]=item;
            }
            var start = new BigNumber(0);
            for(var key_index=0;key_index<all_item_list.length;key_index++){
                var ci  = all_item_list[key_index];
             
                all_item_list[key_index].start =start.toNumber()
                start = start.plus(ci.percent);
                all_item_list[key_index].end = start.toNumber();
                all_item_list[key_index].is_show  = key_index<10?true:false;
                all_item_list[key_index].color_level = color_class_level[ci.quality_color];
                
                // all_item_list[key_index].percent = new BigNumber(all_item_list[key_index].percent).toFixed(8);
                var case_item_id = ci.case_item_id;
                if(case_item_id in item_key_list){
                    
                    var item_key_list_item = item_key_list[case_item_id];

                    for (const property in item_key_list_item) {
                    // for(var key_id in item_key_list_item){
                        if(!(property in ci)){
                            all_item_list[key_index][property]=item_key_list_item[property];
                        }
                    }
                    
                    
                }
            }
            this.all_item_list = all_item_list;
            var bonus_list  = obj.bonus_list;
            this.bonus_list = bonus_list;
            var amount = parseInt($('#bet_amount').text());
            ITEMLIST = case_item;
            currentCase = case_obj.id;
            var bonus_config_id = case_obj.bonus_config_id;
            // console.log(bonus_config_id);

            for(var b_index=0;b_index<bonus_list.length;b_index++){
                var bonus_level =bonus_list[b_index].bonus_level;
                var bonus_start =bonus_list[b_index].bonus_start;
                var bonus_end =bonus_list[b_index].bonus_end;
                var bonus_current =parseFloat(bonus_list[b_index].bonus_current).toFixed(2);
               
                
                if(bonus_list[b_index].gbc_ref_id == bonus_config_id){
                    var percent = parseInt((bonus_current/bonus_end)*100);
                    this.case_obj.bonus_percent[bonus_level] = percent;
                    
                    var range=new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(parseInt(bonus_start))+'-'+new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(parseInt(bonus_end));
                    if(bonus_level<4){
                        this.case_obj.bonus_range[bonus_level] =range;
                    }else{
                        this.case_obj.bonus_range[bonus_level] =formatNumber(bonus_end);
                    }
                    
                    this.case_obj.bonus_current[bonus_level]=formatNumber(bonus_current);
                }

            }
            listCaseGroup = converseCaseGroupNew(case_group);

            this.changeAmount(amount);
            this.initWs();
            

            $('#modal_keep_sell_v2').on('shown.bs.modal', function () {
                    if(SELF_VUE.is_auto){
                        // console.log("show modal on auto");
                        setTimeout(function(){
                            if(SELF_VUE.is_auto){
                                SELF_VUE.sellAllBtn();
                                // console.log('sell click auto');
                                setTimeout(function(){
                                     if(SELF_VUE.is_auto){
                                        // console.log("spin click auto");
                                        SELF_VUE.singleSpin();
                                     }

                                });
                            }
                            
                        },1000*2);
                    }
            });
            //modal-show-reward-item
            $('#modal-show-reward-item').on('shown.bs.modal', function () {
                    if(SELF_VUE.is_auto){
                        // console.log("show modal on auto");
                        setTimeout(function(){
                            if(SELF_VUE.is_auto){
                                SELF_VUE.sellSingleBtn();
                                // console.log('sell click auto');
                                setTimeout(function(){
                                     if(SELF_VUE.is_auto){
                                        // console.log("spin click auto");
                                        SELF_VUE.singleSpin();
                                     }

                                });
                            }
                            
                        },1000*2);
                    }
            });
            
        },

}
function betGameMuti(number, count, test) {
    bonus_reward = false;
    bonus_reward;
    // console.log(bonus_reward);
    lastItemRecieve = [];
    $(' #case-spin-btn,#case-spin-btn-test,#case-spin-btn-all,#case-spin-btn-all-test,#bet_amount').attr('disabled', 'disabled');
    var case_id = currentCase;
    var payout = 1;
    var param = {case_id: case_id, payout: payout, count: count};
    if (token) {
        param['token'] = token;
    }
    sum_count=0;
    if (test != true) {
        $.post(`${SELF_VUE.envDomains.VUE_APP_PES_BASE_URL}/api/game/v1/bets.php`, param, function (response) {
            var msg = response.msg;
            var code = response.code;
            if (code == 1) {
                var price = SELF_VUE.case_obj.price_case;
                SP_BONUS_ITEM=SELF_VUE.pes_profile.sp_bonus_item;
                if(case_id in SP_BONUS_ITEM.spiner){
                    var free_spin = SP_BONUS_ITEM.spiner[case_id];
                    if(free_spin>0){
                    var free_spin_balance = free_spin-count;
                    if(free_spin_balance>0){
                        SELF_VUE.unbox_price_str = 'ฟรีสปิน X'+free_spin_balance+'';
                        SELF_VUE.pes_profile.sp_bonus_item.spiner[case_id]=free_spin_balance;
                        // console.log('free_spin_balance',free_spin_balance);
                    }else if(free_spin_balance<0){

                        SELF_VUE.unbox_price_str = `${SELF_VUE.currency_list[SELF_VUE.currency]}`+(SELF_VUE.case_obj.price_case*count)+(SELF_VUE.currency=='DIAMOND'?'K':'');
                        
                        let balance=new BigNumber(SELF_VUE.pes_profile[SELF_VUE.currency+'_free']);
                        balance = balance.plus(free_spin_balance*price);
                        SELF_VUE.pes_profile[SELF_VUE.currency+'_free'] = (balance.toNumber());
                        
                        // store.commit('updateNeedUserReload', true);
                        SELF_VUE.pes_profile.sp_bonus_item.spiner[case_id]=0;
                        // console.log('free_spin_balance',free_spin_balance);

                    }else{

                        SELF_VUE.unbox_price_str = `${SELF_VUE.currency_list[SELF_VUE.currency]}`+(SELF_VUE.case_obj.price_case*count)+(SELF_VUE.currency=='DIAMOND'?'K':'');
                        SELF_VUE.pes_profile.sp_bonus_item.spiner[case_id]=0;
                        // console.log('free_spin_balance',free_spin_balance);
                    }
                }else{

                    
                    let balance3=new BigNumber(SELF_VUE.pes_profile[SELF_VUE.currency+'_free']);
                    balance3 = balance3.minus(count*price);
                    SELF_VUE.pes_profile[SELF_VUE.currency+'_free'] = (balance3.toNumber());
                    // store.commit('updateNeedUserReload', true);
                    SELF_VUE.unbox_price_str = `${SELF_VUE.currency_list[SELF_VUE.currency]}`+(SELF_VUE.case_obj.price_case*count)+(SELF_VUE.currency=='DIAMOND'?'K':'');
                    // console.log('free_spin_balance','0000');
                }
                    
                }else{
                        let balance2=new BigNumber(SELF_VUE.pes_profile[SELF_VUE.currency+'_free']);
                        balance2 = balance2.minus(count*price);
                        SELF_VUE.pes_profile[SELF_VUE.currency+'_free'] = (balance2.toNumber());
                        
                        SELF_VUE.unbox_price_str = `${SELF_VUE.currency_list[SELF_VUE.currency]}`+(SELF_VUE.case_obj.price_case*count)+(SELF_VUE.currency=='DIAMOND'?'K':'');
                }


                var dataList = response.data;
                lastItemRecieve = dataList;
                SELF_VUE.lastItemRecieve = lastItemRecieve;
                var gidIdList = [];
                // console.log(dataList.length);
                for (var i = 0; i < dataList.length; i++) {
                    var gid = dataList[i].gid;
                    gidIdList.push(gid);
                    // console.log('i>'+i);
                    showBetResultV2(dataList[i], i, false);
                    if ('sp_bonus_item' in dataList[i]) {
                        SP_BONUS_ITEM = dataList[i]['sp_bonus_item'];

                    }
                }
                
            } else {
                if (code == -2) {
                    
                    $('#case-spin-btn-all,#case-spin-btn,#case-spin-btn-all-test,#case-spin-btn-test').removeAttr('disabled');
                    alert(msg);
                    

                } else {
                    $('#case-spin-btn-all,#case-spin-btn,#case-spin-btn-all-test,#case-spin-btn-test,#bet_amount').removeAttr('disabled');
                    alert(msg);
                }
            }
        });
    } else {

        var casse_case_group = listCaseGroup[currentCase];
        var config = getConfig();
        for (var i = 0; i < count; i++) {
            var result = cal(config, casse_case_group,i);
            config.nonce++;
            showBetResultV2(result, i, true);
        }

        updateUserConfigGame(config);
    }

}

function initCaseData(index){
    var amount = 45;
    var fixPercent = 1;
    var itemList = initItemDataGuaranteePercent(index, amount, fixPercent);
    var new_item_list =[];
    for(var i=0;i<itemList.length;i++){
        // console.log(itemList[i].item_name);
        new_item_list.push(itemList[i]);
       
    }
    return new_item_list;
}
function initItemDataGuaranteePercent(case_index, total, fixPercent) {
    fixPercent = fixPercent ? fixPercent : 1;

    var listItem = [];
    var percentSumList = {};
    var percentItemList = {};
    var percentItemCountList = {};
    
    for (var i = 0; i < listCaseGroup[currentCase].length; i++) {
        var obj = iterationCopy((listCaseGroup[currentCase][i]));
        listItem.push(obj);

    }
    // console.log(listItem);
    var percentList = [];
    var totlaSumPercent = new BigNumber(0);
    for (var j = 0; j < listItem.length; j++) {
        var percent = listItem[j].percent;
        totlaSumPercent = totlaSumPercent.plus(percent);
        percentList.push(percent);
        if (percent in  percentSumList) {
            percentSumList[percent] = percentSumList[percent].plus(percent);
            percentItemList[percent].push(j);
        } else {
            percentSumList[percent] = new BigNumber(percent);
            percentItemList[percent] = [j];
        }
    }
    
    var uniqueNames = [];
    $.each(percentList, function (ii, el) {
        if ($.inArray(el, uniqueNames) === -1)
            uniqueNames.push(el);
    });
    percentList = uniqueNames;
    percentList.sort(function (a, b) {
        return a - b
    });
    // var totalAmount = 0;
    for (var k = 0; k < percentList.length; k++) {
        var _percent = percentList[k];
        var amount = Math.ceil(((_percent / 100) * (total)));
        percentItemCountList[_percent] = amount;
        // totalAmount += amount;
    }

    var itemBeforeShffle = [];
    var itemFixed = [];
    for (var _i = 0; _i < percentList.length; _i++) {
        var itemCount = percentItemCountList[percentList[_i]];
        var percent2 = percentList[_i];
        var indexList = getIemShffle(percentItemList[percent2], itemCount);
        if (percent2 < fixPercent) {
            for (var index1 = 0; index1 < indexList.length; index1++) {
                var indexItem1 = indexList[index1];
                itemFixed.push(iterationCopy(listItem[indexItem1]));
            }
        } else {
            for (var index2 = 0; index2 < indexList.length; index2++) {
                var indexItem2 = indexList[index2];
                itemBeforeShffle.push(iterationCopy(listItem[indexItem2]));
            }
        }

    }
    // console.log(itemFixed.length);
    // console.log(itemBeforeShffle);
    // return;
    var itemAfterShuffle = shuffleWithNumber(itemBeforeShffle, total - itemFixed.length);

    for (var i2 = 0; i2 < itemFixed.length; i2++) {
        itemAfterShuffle.push(itemFixed[i2]);
    }
    // console.log("V2. Guarantee item under 1% : "+itemFixed.length+" piece");
    itemAfterShuffle = shuffle(itemAfterShuffle);
    // console.log(itemAfterShuffle);

    if (itemAfterShuffle.length < total) {
        for (var index = itemAfterShuffle.length; index < total; index++) {
            var random = Math.floor(Math.random() * listItem.length);
            itemAfterShuffle.push(iterationCopy(listItem[random]));

        }
    }
    // console.log("size:"+itemAfterShuffle.length);
    return itemAfterShuffle;

}
function getIemShffle(a, number) {
    
    // console.log(idList);
    var data = [];
    var radio = Math.ceil(number / a.length);
    // console.log("radio:"+radio);
    if (radio > 1) {
        // var _radio = Math.ceil(a.length / number);
        // console.log("RADIO:"+_radio);
        for (let i = 0; i < a.length; i++) {
            for (var index = 0; index < radio; index++) {
                data.push(a[i]);
            }
        }
    } else {
        for (let i = a.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [a[i], a[j]] = [a[j], a[i]];
        }

        for (let i = 0; i < radio; i++) {
            data.push(a[i]);
        }
    }
    return data;
}
function iterationCopy(src) {
    let target = {};
    for (let prop in src) {
        if (prop in src) {
            target[prop] = src[prop];
        }
    }
    return target;
}
function shuffleWithNumber(a, number) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    var data = [];
    for (var i = 0; i < number && i < a.length; i++) {
        data.push(a[i]);
    }
    return data;
}
function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}
function converseCaseGroupNew(data) {
    var dataReturn = {};

    for (var i = 0; i < data.length; i++) {
        var result = getItemListByCaseId(data[i]);
        if (data[i]['case_case_id'] in dataReturn) {
            dataReturn[data[i]['case_case_id']].push(result);
        } else {
            dataReturn[data[i]['case_case_id']] = [];
            dataReturn[data[i]['case_case_id']].push(result);

        }
    }
    return dataReturn;
}
function getItemListByCaseId(obj) {
    var result;
    for (var i = 0; i < ITEMLIST.length; i++) {
        // console.log(obj.case_item_id+":"+itemlist[i].id);
        if (obj.case_item_id == ITEMLIST[i].id) {
            // indexRemove = i;
            result = iterationCopy(ITEMLIST[i]);
            result.percent = obj.percent;
            break;
        }
    }

    return result;
}
function numberWithCommasNormal(x) {

    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //console.log(x);
    //console.log(parts.join("."));
    return parts.join(".");


}
function showBetResultV2(data, number, test) {
    
    $("#main-case-box-" + number).find(".case-inner").css({transform: "translateX(0px) translateZ(0)"});
    $("#main-case-box-" + number).find(".case-container").show();
    $("#main-case-box-" + number).find(".case-image").hide();
    $("#main-case-box-" + number).find("#case-img").hide();
    $("#main-case-box-" + number).find("#case-reward").hide();
    $("#main-case-box-" + number).find(".case-choice-btn").addClass("disabled-btn").removeClass("hoverable").removeClass("waves-light").addClass("waves-none").css("cursor", "default");

    var is_mute = $('#is_mute').attr('_value');
    // console.log(is_mute);
    if (is_mute==0) {
        // console.log("play odn start...");
        var promise = case_start.play();
        if (promise) {
            //Older browsers may not return a promise, according to the MDN website
            promise.catch(function (error) {
                console.log("error play sound start");
                console.error(error);
            });
        }
    }


    let offset = 0;
    if (offsetOverride >= 0 && offsetOverride <= 5) {
        offset = offsetOverride;
    } else {
        offset = Math.random();
    }
    let markerOffset = $("#main-case-box-" + number).find(".case-container").width() / 2;
    var targetPositionLeft = $('#div-case-item-' + number + '-37').offset().left;
    var firstPositionLeft = $('#div-case-item-' + number + '-0').offset().left;
    // offset=0;
    let finalSpinnerPosition = (-1 * ((targetPositionLeft - firstPositionLeft) - markerOffset)) - (180 * offset);

    //scale(0.9, 0.835)
    $("#main-case-box-" + number).find(".case-inner").addClass("rolling").css({transform: "translateX(" + finalSpinnerPosition * 1 + "px) translateZ(0)"});
    let last = 0;
    if (number == 0) {
        // var bezierEasing = [0, 0.5, 0.5, 0];
        // var tFunc = "cubic-bezier(" + bezierEasing.join(",") + ")";
        // $("#trans").css("-webkit-translation-timing-function", tFunc);
        
        
        $({tracker: 500}).animate({tracker: finalSpinnerPosition}, {duration: 9500, easing: $.bez([.31, .9985, .31, .9985]),
            step: function (now) { // called every frame
                if (last > Math.floor((now - markerOffset - 5) / 200)) {
                    playClick();
                    last = Math.floor((now - markerOffset - 5) / 200);
                }
            }
        })
    }

    setTimeout(() => {
        starburst.show();
        $("#main-case-box-" + number).find(".case-inner").removeClass("rolling")
        setTimeout(function () {
            $("#main-case-box-" + number).find("#case-spin-btn").show();
            sum_count++;

            var amount = num;
            if (sum_count == amount) {
                if (!$('#case-spin-btn-all').hasClass('btnNonebalance')) {
                    $('#case-spin-btn-all').removeAttr('disabled');
                }

                $('#case-spin-btn,#case-spin-btn-test,#case-spin-btn-all-test,#bet_amount').removeAttr('disabled').removeClass('spin_balance_not_enough').removeClass('active');
                spin_again = true;
                // console.log(":::"+ (test!=true) +":"+ (user_opened));
                if (test != true) {
                    
                    showAllItem();
                }else if(is_test){
                    
                    showAllItem();
                }else{
                    showSpinBtn(SELF_VUE.bet_amount);
                   
                }
                

            }

        }, 333)

        if (number == 0) {
            var is_mute = $('#is_mute').attr('_value');
            if (is_mute==0) {

                setTimeout(function () {
                    var color_level_show =6;
                    var color_name ='b4b4b2';
                     
                    for(let i=0;i<lastItemRecieve.length;i++){
                        let color_level=color_class_level[lastItemRecieve[i].name_color];
                        if(color_level<color_level_show){
                            color_level_show = color_level;
                            color_name=lastItemRecieve[i].name_color;
                        }
                        // lastBalance = lastItemRecieve[i].balance_free;
                    }
                    // console.log(lastBalance);
                    if(color_level_show<6){
                        var color_map = color_class_mapping[color_name];
                        case_done[color_map].play();
                    }

                    
                    // if (promise) {
                    //     //Older browsers may not return a promise, according to the MDN website
                    //     promise.catch(function (error) {
                    //         console.log("error play sound on end .");
                    //
                    //
                    //     });
                    // } else {
                    //
                    // }
                }, 100);

            }

        }

        $("#main-case-box-" + number).find(".case-image").show();
        $("#main-case-box-" + number).find("#case-spin-btn").text("Spin Again!")
        $("#main-case-box-" + number).find(".case-choice-btn").removeClass("disabled-btn").addClass("hoverable").addClass("waves-light").removeClass("waves-none").css("cursor", "pointer");
        $("#main-case-box-" + number).find("#grate-img").animate({top: -250}, 1250, $.bez([0.42, 0, .8, 1]));
    }, 9500);
    updateRewardItem(number, data);

}
function getConfig() {
    return userConfigGame;
}
function cal(config, case_case_group,count) {
    var rewardPosition = [];
    var last_position_percent = new BigNumber(0);
    var jackpots_index = 0;
    var min_percent = 100;
    var game_change = 0;
    for (var i = 0; i < case_case_group.length; i++) {
        var item = case_case_group[i];
        var percent = item.percent;
        if (percent < min_percent) {
            jackpots_index = i;
            min_percent = percent;
        }
        var positionArray = {
            start: last_position_percent.toNumber(),
            end: 0,
            size: percent,
            is_jackpots: 0,
            is_profit: item.item_price > config.cost ? 1 : 0,
        };
        if (positionArray['is_profit'] == 1) {
            game_change += percent;
        }
        last_position_percent = last_position_percent.plus(percent);
        positionArray['end'] = last_position_percent.toNumber();
        rewardPosition[i] = positionArray;
    }
    var calResult = calGamePro(config.server_secret_seed, config.client_seed, config.nonce);
    var result = new BigNumber(calResult);
    var reward_index = 0;
    for (var i2 = 0; i2 < rewardPosition.length; i2++) {
        var positionArray2 = rewardPosition[i2];
        var start = new BigNumber(positionArray2['start']);
        var end = new BigNumber(positionArray2['end']);
        if (result.gte(start) && result.lt(end)) {
            reward_index = i2;
            break;
        }
    }
    rewardPosition[jackpots_index]['is_jackpots'] = 1;
    var object = {
        'server_seed': config.server_secret_seed,
        'client_seed': config.client_seed,
        'nonce': config.nonce,
        'result': calResult,
        'reward_position': rewardPosition,
        'reward_index': reward_index,
        'game_change': game_change,
        'is_jackpots': rewardPosition == jackpots_index ? 1 : 0,
    }
    if(is_test){
        
        var item2=item_test_list[item_test_count][count];
        item2.result_return =item2.prices;
        return item2;
        
    }else{
        return object;
    }
    // console.log(object);
    // return object;

}
function updateUserConfigGame(config) {
    var json = JSON.stringify(config);
    window.localStorage.setItem('userConfigGame', json);
}
function playClick() {
    if (canPlayClick) {
        canPlayClick = false;
        var is_mute = $('#is_mute').attr('_value');
        if (is_mute==0) {
            var promise = clicks[clicknum++ % 20].play();
            if (promise) {
                //Older browsers may not return a promise, according to the MDN website
                promise.catch(function (error) {
                    console.error(error);
                });
            }
        }

        setTimeout(function () {
            canPlayClick = true;
        }, 200)
    } else {
        if (clickInterval == 0) {
            clickInterval = setInterval(function () {
                if (canPlayClick) {
                    setTimeout(function () {
                        canPlayClick = true;
                    }, 200)
                    var is_mute = $('#is_mute').attr('_value');
                    if (is_mute==0) {
                        var promise = clicks[clicknum++ % 20].play();
                        if (promise) {
                            //Older browsers may not return a promise, according to the MDN website
                            promise.catch(function (error) {
                                console.error(error);
                            });
                        }
                    }

                    clearInterval(clickInterval);
                    clickInterval = 0;
                    canPlayClick = false;
                }
            }, 5);
        }
    }
}
function calGamePro(serverSeed, clientSeed, nonce) {
    var hash = sha512(serverSeed + '+' + clientSeed + '-' + nonce);
    var index = 0;
    do {
        var lucky = parseInt(hash.substr(index, 8), 16);
        index += 8;
    } while (lucky >= 1000000000);
    return (lucky % 10000000) / 100000;
}
function showAllItem() {
    if (bonus_reward) {
        //show
        var b = bonus_obj;
        var level = b.bonus_level;
        var bonus = level < 4 ? b.bonus_current : b.bonus_end;
        var bonus_show = numberWithCommasNormal((bonus));
        if (level == 0) {
            $('#game_bonus_value_div').html(bonus_show);
        } else if (level == 1) {
            $('.content-popup-major').children().text(bonus_show);
        } else if (level == 2) {
            $('.content-popup-mini').children().text(bonus_show);
        } else if (level == 3) {
            $('.content-popup-mini').children().text(bonus_show);
        } else {
            $('.content-popup-bonus').children().text(bonus_show);
        }

        setTimeout(function () {
            // $('#modal_bonus').modal(modal_option);

            showBonusModal(level);
        }, 700);

        // $('#bonus_text').html('BONUS '+numberWithCommasNormal(bonus));
    } else {
        showAllItemReward();
    }
}
function showBonusModal(level) {
    // var html = $('#myModal2').html();
    if (level == 0) {
        $('#ModalGrand').show();
        $('#ModalGrand').children('div').children('div.close_modal_bonus').removeAttr('disabled');
        var rect = $('.parallax')[0].getBoundingClientRect();
        var mouse = {x: 0, y: 0, moved: false};
        $(document).on('mousemove', '.parallax', function (e) {
            // $(".parallax").mousemove(function (e) {
            mouse.moved = true;
            mouse.x = e.clientX - rect.left;
            mouse.y = e.clientY - rect.top;
        });

        // // Ticker event will be called on every frame
        // TweenLite.ticker.addEventListener('tick', function () {

        //     if (mouse.moved) {
        //         parallaxIt(".item-side1", -100);
        //         parallaxIt(".item-side2", -10);
        //         parallaxIt(".item-side3", -50);
        //     }
        //     mouse.moved = false;
        // });

        
        $(window).on('resize scroll', function () {
            rect = $('.parallax')[0].getBoundingClientRect();
        })
    } else if (level == 1) {
        $('#ModalMajor').modal(modal_option);
        $('#ModalMajor').children('div').children('div.close_modal_bonus').removeAttr('disabled');

    } else if (level == 2) {
        $('#ModalMinor').modal(modal_option);
        $('#ModalMinor').children('div').children('div.close_modal_bonus').removeAttr('disabled');
    } else if (level == 3) {
        $('#ModalMini').modal(modal_option);
        $('#ModalMini').children('div').children('div.close_modal_bonus').removeAttr('disabled');
    } else {
        $('#ModalBonus').modal(modal_option);
        $('#ModalBonus').children('div').children('div.close_modal_bonus').removeAttr('disabled');
    }
}
// function parallaxIt(target, movement) {
//             TweenMax.to(target, 0.3, {
//                 x: (mouse.x - rect.width / 2) / rect.width * movement,
//                 y: (mouse.y - rect.height / 2) / rect.height * movement
//             });
// }

function showAllItemReward() {
    if(is_test){
        console.log('is_test');
        if (item_test_list[item_test_count].length > 1) {
            $('#sell-all-btn').removeAttr('disabled').css('color', 'white');
            
        } else {
            $('#sell-all-btn').removeAttr('disabled').css('color', 'white');
            
        }

    }else{

        if (lastItemRecieve.length > 1) {
            $('#sell-all-btn').removeAttr('disabled').css('color', 'white');
            setTimeout(function () {
                $('#modal_keep_sell_v2').modal(modal_option);
                var all_sum_price = new BigNumber(0);
                for (var i = 0; i < lastItemRecieve.length; i++) {``
                    all_sum_price = all_sum_price.plus(lastItemRecieve[i].result_return);
                }
                SELF_VUE.total_sum = all_sum_price.toNumber();
                SELF_VUE.item_multi = lastItemRecieve;
               

                var balance  = new BigNumber(SELF_VUE.pes_profile[SELF_VUE.currency+'_free']);
                if(balance.lt(SELF_VUE.case_obj.price_case*SELF_VUE.bet_amount)){
                    $('#case-spin-btn-all').attr('disabled','disabled').addClass('spin_balance_not_enough');
                    if(SELF_VUE.is_auto){
                        SELF_VUE.clickStopAuto();
                        
                    }
                }else{
                    $('#case-spin-btn-all').removeAttr('disabled').removeClass('spin_balance_not_enough');
                }
            }, 500);
        } else {
            var data2 = lastItemRecieve[0];
            $('#modal-show-reward-item').modal(modal_option);
            SELF_VUE.item_single = data2;
            var balance2  = new BigNumber(SELF_VUE.pes_profile[SELF_VUE.currency+'_free'])
                if(balance2.lt(SELF_VUE.case_obj.price_case*SELF_VUE.bet_amount)){
                    $('#case-spin-btn-all').attr('disabled','disabled').addClass('spin_balance_not_enough');

                    if(SELF_VUE.is_auto){
                        SELF_VUE.clickStopAuto();
                        
                    }
                }else{
                    $('#case-spin-btn-all').removeAttr('disabled').removeClass('spin_balance_not_enough');
                }
        }
    }


}
function updateRewardItem(number, item) {
    if (spin_again == true) {
        initCaseData(number);
    }
    var div = $('#div-case-item-' + number + '-37');
    div.children('img').attr("src", item.icon_url);
    var color = item.name_color;
    var item_name = item.item_name;
    div.removeClass('case-item-purple ' +
            'case-item-red ' +
            'case-item-pink ' +
            'case-item-white ' +
            'case-item-blue ' +
            'case-item-gold');
    var bg_color_class = color_class_mapping[color];
    div.addClass('case-item-' + bg_color_class);
    color = '#' + color;
    div.css('border-left-color', color);
    div.css('border-right-color', color);
    var rgbaCol = 'rgba(' + parseInt(color.slice(-6, -4), 16)
            + ',' + parseInt(color.slice(-4, -2), 16)
            + ',' + parseInt(color.slice(-2), 16)
            + ',0.3)';
    div.css('background-color', rgbaCol);
    var rgbaCol2 = 'rgba(' + parseInt(color.slice(-6, -4), 16)
            + ',' + parseInt(color.slice(-4, -2), 16)
            + ',' + parseInt(color.slice(-2), 16)
            + ',0.9)';
    div.children('div.border-top-style').css('background-color', rgbaCol2);
    // div.css('o',color);
    // div.children('div.case-item-value').attr('style',style);
    div.children('div.case-item-value').children('div.item-name').html(item_name);
}

function initUserConfigGame() {
    var hasConfig = window.localStorage.getItem('userConfigGame');
    if (hasConfig) {
        var config = window.localStorage.getItem('userConfigGame');
        userConfigGame = JSON.parse(config);
    } else {
        $.get(`${SELF_VUE.envDomains.VUE_APP_PES_BASE_URL}/api/v1/test/getUserConfigGame.php`, function (response) {
            if (response.code == 1) {
                var data = response.data;
                var json = JSON.stringify(data);
                config = data;
                window.localStorage.setItem('userConfigGame', json);
                userConfigGame = data;
            } else {
                alert(response.msg);
            }
        });
    }
}
function showSpinBtn(amount){
                                $('#case-spin-btn-all').removeAttr('disabled');
                                var check_balance=true;
                                if(SELF_VUE.case_obj.id in SELF_VUE.pes_profile.sp_bonus_item.spiner){
                                    var free_spin = SELF_VUE.pes_profile.sp_bonus_item.spiner[SELF_VUE.case_obj.id];
                                    // console.log(free_spin);
                                    if(free_spin>0){
                                        SELF_VUE.unbox_price_str = 'ฟรีสปิน X'+free_spin+'';
                                        if(free_spin>=amount){
                                            check_balance=false;
                                        }
                                
                                    }else{
                                        SELF_VUE.unbox_price_str = `${SELF_VUE.currency_list[SELF_VUE.currency]}`+(SELF_VUE.case_obj.price_case*amount)+(SELF_VUE.currency=='DIAMOND'?'K':'');
                                        
                                    }
                                }else{
                                    SELF_VUE.unbox_price_str = `${SELF_VUE.currency_list[SELF_VUE.currency]}`+(SELF_VUE.case_obj.price_case*amount)+(SELF_VUE.currency=='DIAMOND'?'K':'');
                                    // console.log(SP_BONUS_ITEM.spiner);
                                }
                                if(check_balance){
                                    var balance = new BigNumber(SELF_VUE.pes_profile[SELF_VUE.currency+'_free']);
                                    if(balance.lt(amount*SELF_VUE.case_obj.price_case)){
                                        console.log("balance not enough");
                                        $('#case-spin-btn-all').attr('disabled','disabled').addClass('spin_balance_not_enough');

                                    }else{
                                        $('#case-spin-btn-all').removeAttr('disabled').removeClass('spin_balance_not_enough');
                                    }
                                }
}
function  manageWSContent(response,page){
                    var type = response.type;
                    var data = response.data;
                    let currency = response.currency;
                    if(page=='all') {
                        if (type == 'chat') {
                            var init1 = response.init;
                            init1;
                        }else if(type=='balance'){
                            setTimeout(function(){
                                
                                SELF_VUE.pes_profile[currency+'_free'] = data;
                                // store.commit('updateNeedUserReload', true);
                            },1000*10.5);
                            
                        }else if(type=='unbox-packet'){

                            if(!(data.ref_id+'' in SELF_VUE.pes_profile.sp_bonus_item.spiner)){
                                SELF_VUE.pes_profile.sp_bonus_item.spiner[data.ref_id]=0;
                            }
                            SELF_VUE.pes_profile.sp_bonus_item.spiner[data.ref_id]=data.count;
                            SELF_VUE.pes_profile[SELF_VUE.currency+'_free'] = data.balance;
                            // store.commit('updateNeedUserReload', true);
                            if(!$('#case-spin-btn-all').attr('disabled')){
                                showSpinBtn(SELF_VUE.bet_amount);
                            }
                        
                        } else if (type == 'bonus') {
                            for (var i = 0; i < data.length; i++) {
                                var b = data[i];
                                if (b != null) {
                                    var bonus_config_id = b.gbc_ref_id;
                                    var bonus_current = b.bonus_current;
                                    var bonus_status = b.bonus_status;
                                    var bonus_level = b.bonus_level;
                                    var bonus_start = b.bonus_start;
                                    // var bonus_start = b.bonus_start;
                                    var bonus_end = b.bonus_end;
                                    if(SELF_VUE.case_obj==undefined){
                                        SELF_VUE.bonus_list[bonus_config_id].bonus_percent[bonus_level] = parseInt((bonus_current/bonus_end)*100);
                                        if (bonus_status == 0 && bonus_level != 4) {
                                            updateBonusNumber('.bonus-'+bonus_config_id+'-'+bonus_level,bonus_current,2);
                                        } else if (bonus_level != 4) {
                                            updateBonusNumber('.bonus-'+bonus_config_id+'-'+bonus_level,bonus_current,2);
                                        }
                                        if(bonus_level==0 || bonus_level==1){
                                            bonus_level;
                                            // manageShowUnboxBonus(b);

                                        }
                                    }else if(SELF_VUE.case_obj){    
                                        SELF_VUE.case_obj.bonus_percent[bonus_level] = parseInt((bonus_current/bonus_end)*100);
                                        if(SELF_VUE.case_obj.bonus_current[bonus_level]==0){
                                            var bonus_range;
                                            if(bonus_level<4){
                                                bonus_range=new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(bonus_start)+'-'+new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(bonus_end);
                                            }else{
                                                bonus_range=new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 2 }).format(bonus_end);
                                            }
                                            SELF_VUE.case_obj.bonus_range[bonus_level] = bonus_range;
                                        }

                                        // console.log(SELF_VUE.case_obj.bonus_percent[bonus_level]);
                                        if (bonus_status == 0 && bonus_level != 4) {
                                            updateBonusNumber('.bonus-'+bonus_config_id+'-'+bonus_level,bonus_current,2);
                                        } else if (bonus_level != 4) {
                                            updateBonusNumber('.bonus-'+bonus_config_id+'-'+bonus_level,bonus_current,2);
                                        }
                                        if(bonus_level==0 || bonus_level==1){
                                            bonus_level;
                                            // manageShowUnboxBonus(b);

                                        }
                                    }
                                
                                    
                                }

                            }
                        } else if (type == 'bonus_reward') {
                            showBonusReward(response);
                        } else if (type == 'item_recent') {
                            type;
                            // // $('#item_recent_left').html('');
                            // for (var i = 0; i < data.length; i++) {
                            //     updateContentGame(response.data[i]);
                            // }
                        }else if(type=='noti_msg') {
                            type;
                            // var noti_count = data.noti_count;
                            // $('div.noti-box').html('<span class="label">' + noti_count + '</span>');
                            // $('span.noti-box-right').html(noti_count);
                        }else if(type=='lotto_noti1') {
                            // console.log("have>>>>");
                            type;
                            // manageLottoNoti1(data);


                        }else if(type=='lotto_noti2'){
                            type;
                            // updateContentGame(response.data);
                        } else if (response.code == 1) {
                            type;
                            // manageGameData(response);
                        }
                    }else if(page=='spiner'){
                        page;

                    }else if(page=='mini_game'){
                        if (type == 'chat') {
                            var init2 = response.init;
                            init2;
                            // manageChat(data, init);
                        }else if(type=='last_result'){
                            type;
                            // var is_mobile = path.includes("/m/");
                            // if(response.hasOwnProperty('data')){
                            //     var list = response.data;

                            //     for(var i=0;i<list.length;i++){
                            //         var html = is_mobile?lastResultTrMobile(list[i]):lastResultTr(list[i]);
                            //         if(is_mobile){
                            //             $('#collapseTopJackpot').children('div').append(html);
                            //         }else{
                            //             $('#ul-game-list').append(html);
                            //         }

                            //     }
                            // }else{
                            //     var html = is_mobile?lastResultTrMobile(response):lastResultTr(response);
                            //     var second = 7000;
                            //     if(response.game_name=='Hilo'){
                            //         second=3000;
                            //     }else if(response.hasOwnProperty('delay')){
                            //         second=delay_second[response.delay];
                            //     }
                            //     setTimeout(function(){
                            //         if(is_mobile){
                            //             $('#collapseTopJackpot').children('div').prepend(html);
                            //         }else{
                            //             $('#ul-game-list').append(html);
                            //         }
                            //     },second);

                            // }
                        }else if(type=='noti_msg'){
                            type;
                            // var noti_count=data.noti_count;
                            // $('div.noti-box').html('<span class="label">'+noti_count+'</span>');
                            // $('span.noti-box-right').html(noti_count);
                        }
                    }else{
                        type;
                    }

                } 
function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

function  wsSendData(_data) {
     ws.send(JSON.stringify(_data));
}
function updateBonusNumber(id,value){

    $(id).each(function() {
    var self = $(this),
    countTo = value;

    $({
        countNum: self.text().replace(",",'')
    }).animate({
        countNum: countTo
        },

        {
        duration: 500,
        easing: 'linear',
        step: function() {
        
            self.text(commaSeparateNumber(parseFloat(this.countNum).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')));
            
        },
        complete: function() {
            self.text(commaSeparateNumber(parseFloat(this.countNum).toFixed(3).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')));
            // alert('finished');
        }
        }
    );

    });
}
function commaSeparateNumber(val) {
  while (/(\d+)(\d{3})/.test(val.toString())) {
    val = val.toString().replace(/(\d+)(\d{3})/, '$1' + ',' + '$2');
  }
  return val;
}
function showBonusReward(b){

    if(b.game=='spiner'){
        var bonus = b.data[0];
        var bonus_modal = BONUS_LEVEL_MODAL[bonus.bonus_level];
        
        if(SELF_VUE.case_obj){
            // SELF_VUE.case_obj.bonus_current[bonus.bonus_level] = bonus.bonus_current;
            SELF_VUE.$set(SELF_VUE.case_obj.bonus_current,bonus.bonus_level, bonus.bonus_current)
            // console.log(bonus.bonus_level, SELF_VUE.case_obj.bonus_current[bonus.bonus_level]);
        }else{
            SELF_VUE.bonus[bonus.bonus_level].bonus_current = parseFloat(bonus.bonus_current).toFixed(2);
            SELF_VUE.bonus[bonus.bonus_level].bonus_end = bonus.bonus_end;
        }

        if(bonus.bonus_user_id==SELF_VUE.pes_profile.user_id){
            
            setTimeout(function(){
                // console.log(bonus.bonus_level, SELF_VUE.case_obj.bonus_current[bonus.bonus_level]);
                if(bonus.bonus_level==0){
                    $('#'+bonus_modal).show();
                }else{
                    $('#'+bonus_modal).modal(modal_option);
                }
            },8500);

            
            if(SELF_VUE.is_auto){
                setTimeout(function(){
                    // console.log('close modal auto');
                    // console.log(bonus.bonus_level, SELF_VUE.case_obj.bonus_current[bonus.bonus_level]);
                    if(bonus.bonus_level==0 || bonus.bonus_level==4){
                        // console.log('#'+bonus_modal+' grand is hide')
                        $('#'+bonus_modal).hide();
                    }else{
                        // console.log('#'+bonus_modal+' is hide')
                        $('#'+bonus_modal).modal('hide');
                    }

                    
                },9500);
            }
        }

       
    }
}

</script>
<style src="@/assets/css/unbox.css?id=13" scoped></style>
<style>

    /*.GameType .MenuBar.box-all-games{display: none!important}*/
    @media (orientation: landscape) {
        /*.GameType .MenuBar.box-all-games{display: block!important}*/
    }
</style>
<style scoped>
    /*.main-spin-content{overflow-x: hidden;height: calc(100vh - 123px);}*/
    .GameType .MenuBar{background-image: none}
    .total-box1{
        display: flex;
        justify-content: space-between;
        font: 600 16px Athiti;
        background-color: #eee;
        border-radius: 10px;
        padding: 5px;
        margin: 10px 10px 0;
        color: #555;
        position: relative;
        z-index: 4;
    }


    .wrapper-case{padding-bottom: 0;}
    .content-page {
        /*height: calc(100vh - 60px);*/
        /*height: 90vh;*/
        /*background-color: #fff;*/
        /*border-radius: 20px 20px 0px 0px;*/
        /*margin-top: -15px;*/
        /*padding: 15px;*/
    }

    .GameType {
        background-image: linear-gradient(
                135deg, #F372D4 10%, #c765f0 100%);
    }

    .box-user-img, .box-user-img img {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
    }

    .box-img, .box-menu-img {
        display: flex;
        color: #54117f;
        font-family: "Athiti", sans-serif;
    }

    .box-img {
        flex: 3;
        height: unset;
    }

    .box-img small {
        display: block;
        margin-top: -5px;
    }

    .box-list-menu {
        padding: 0px 10px;
    }

    .box-list-menu p {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #54117f;
        font-family: "Athiti", sans-serif;
    }

    .wrapper-box{
        margin-top: 15px;
    }

    .box-games-list{
        width: 145px;
        padding: 0px;
        border-radius: 10px;
    }
    .box-img-list{
        border-radius: 10px;
        background-size: cover;
        background-position: center;
        height: 80px;
        background-image: url(../assets/images/casino/sexy-baccarat/sexy-bacarat.png);
    }

    .box-type{
        font-size: 13px;
        margin-bottom: 5px;
        clear: both;
        height: 20px;
    }

    .box-type > .txt-header{
        color: #54117f;
        font-weight: 600;
    }

    .box-type a{
        color: #888;
    }

    .box-type img{
        height: 20px;
        margin-top: -5px;
    }

    .wrapper-box-type{
        margin-bottom: 10px;
    }


</style>